import gql from 'graphql-tag'

export default gql`{
   _id
    show
    title
    position
    description
    image
}
`