<template>
  <div class="fill-heigt pt-0" fluid>
    <v-row class="d-flex flex-row py-1">
      <div class="text-left subtitle px-3" style="margin-top:2%">
        Pagina do recrutador
      </div>
      <v-spacer></v-spacer>
      <v-col cols="3" md="2" class="text-right px-0 mt-1">
        <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="9" md="4">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            label="Pesquisar pelo titulo"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3" style="margin-top:-7%"></v-divider>

    <v-row class="py-0 px-2">
      <v-col cols="12" md="9">
        <v-row class="d-flex flex-row align-center py-4">
        
        </v-row>
      </v-col>
      <v-col cols="12" md="3" class="text-right justify-end">
        <div class="d-flex justify-end">
          <v-col cols="12" md="3" class="text-right justify-end">
            <div class="d-flex justify-end">
              <v-btn dense color="primary"  @click.prevent="dialogSlideAdd()">
                <v-icon left>mdi-plus</v-icon>Criar novo conteudo
              </v-btn>
            </div>
          </v-col>
        </div>
      </v-col>
    </v-row>

    <infinite-scroll
      :items="contents"
      :totalPages="totalPages"
      @refetch="fetch"
    >
      <template v-slot:item="{ item }">
        <card
          :test="item"
          @delete="deleteContent(item._id)"
          @edit="dialogSlideAdd(item._id)"
        />
        <v-skeleton-loader
          v-show="$apollo.loading"
          elevation="2"
          v-bind="filteredItems"
          type="image, article"
        ></v-skeleton-loader>
      </template>
    </infinite-scroll>
    <formAdd :id="id" v-if="form" @back="closeDialog"></formAdd>
    <progressLoad v-if="progressbar"></progressLoad>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import formAdd from "./../components/formAdd";
import card from "./../components/card";
import InfiniteScroll from './../components/InfiniteScroll.vue'
import progressLoad from "./../components/progress";
import { CONTENT_RECRUTER_QUERY } from "./../graphql/Query";
import { UPDATED_CONTENT_RECRUTER } from "./../graphql/subscription";
import { DELETE_CONTENT_RECRUTER } from "./../graphql/Mutation";
import { API_URL } from "./../../../../../../api";
export default {
  components: {
    formAdd,
    progressLoad,
    card,
    InfiniteScroll
  },
  data: () => ({
    search: "",
    model: 0,
    colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
    contents: [],
    file: false,
    imagesUrl: [],
    uri: API_URL + "/images/recrutercontent/",
    id: "",
    form: false,
    progressbar: false,
  }),
  apollo: {
    getContentRecruter: {
      query: CONTENT_RECRUTER_QUERY,
      fetchPolicy: "no-cache",

      result({ data }) {
        if (data) {
          this.contents = data.getContentRecruter;
        }
      },
    },

    $subscribe: {
      message: {
        query: UPDATED_CONTENT_RECRUTER,
        result({ data }) {
          if (data.addRecruterContent) {
            const index = this.contents.findIndex(
              (r) => r._id === data.addRecruterContent._id
            );
            console.log("Indice", index);
            if (index > -1) {
              this.contents[index] = data.addRecruterContent;
              console.log("contentUpdate", data.addRecruterContent);
            } else {
              this.contents.push(data.addRecruterContent);
            }
            console.log("contentUpdate", this.contents);
          }
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },

  methods: {
    dialogSlideAdd(id) {
     console.log('buscar id', id);
      if (id != undefined) {
        this.id = id;
      } else {
        this.id = null;
      }

      this.form = true;
      // eslint-disable-next-line no-undef
      Fire.$emit("dialogSlideAdd");
    },
    closeDialog() {
      this.form = false;
    },
    async deleteContent(id) {
      this.progressbar = true;
      await this.$apollo
        .mutate({
          mutation: DELETE_CONTENT_RECRUTER,
          variables: {
            id: id,
          },
        })
        .then((data) => {
          // console.log('Content', data.data)
          this.progressbar = false;
          this.contents = data.data.deleteContentRecruter;
          this.text = "Conteudo eliminado com sucesso!";
          this.snackbar = true;
          this.$emit("back");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
